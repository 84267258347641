import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Faq = () => {
  return (
    <>
      <Header />
      <main style={{ paddingTop: "170px" }}>
        {/* Breadcrumb Start */}
        <div className="breadcrumb-section" style={{ paddingTop: "0" }}>
          <div className="container-fluid custom-container">
            <div className="breadcrumb-wrapper text-center">
              <h2 className="breadcrumb-wrapper__title">FAQ’s</h2>
              <ul className="breadcrumb-wrapper__items justify-content-center">
                <li>
                  <a href="#/">Home</a>
                </li>
                <li>
                  <span>FAQ’s</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Breadcrumb End */}
        {/* FAQ’s Start */}
        <div className="faq-section section-padding-2">
          <div className="container-fluid faq-container">
            {/* FAQ’s Wrapper Start */}
            <div className="faq-wrapper">
              {/* FAQ’s Start */}
              <div className="faq-accordion">
                <h2 className="faq-accordion__title">Shopping Information</h2>
                <div className="accordion">
                  <div className="accordion-item">
                    <button
                      className="collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqOne"
                    >
                      <span className="text">
                        How can I order my favorite CA piece?
                      </span>
                      <span className="icon">
                        <i className="lastudioicon-down-arrow" />
                      </span>
                    </button>
                    <div id="faqOne" className="accordion-collapse collapse">
                      <p>
                        Kindly send us a DM or click the WhatsApp link at the
                        bottom right of your screen to order.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <button
                      className="collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqTwo"
                    >
                      <span className="text">
                        How long does it take to receive my order?
                      </span>
                      <span className="icon">
                        <i className="lastudioicon-down-arrow" />
                      </span>
                    </button>
                    <div id="faqTwo" className="accordion-collapse collapse">
                      <p>
                        Processing and delivery of orders take 5 to 7 days.
                        However, express delivery within a shorter time frame is
                        possible but will attract extra cost.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <button
                      className="collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqThree"
                    >
                      <span className="text">
                        What payment methods are accepted?
                      </span>
                      <span className="icon">
                        <i className="lastudioicon-down-arrow" />
                      </span>
                    </button>
                    <div id="faqThree" className="accordion-collapse collapse">
                      <p>We accept bank transfers.</p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <button
                      className="collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqFour"
                    >
                      <span className="text">
                        Are your pieces true to size?
                      </span>
                      <span className="icon">
                        <i className="lastudioicon-down-arrow" />
                      </span>
                    </button>
                    <div id="faqFour" className="accordion-collapse collapse">
                      <p>Yes, most of our pieces are true to size.</p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <button
                      className="collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqFive"
                    >
                      <span className="text">
                        How can I take care of my outfits?
                      </span>
                      <span className="icon">
                        <i className="lastudioicon-down-arrow" />
                      </span>
                    </button>
                    <div id="faqFive" className="accordion-collapse collapse">
                      <p>
                        You can hand wash or dry clean but be cautious with
                        embellishments and feathers.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <button
                      className="collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqSix"
                    >
                      <span className="text">What is the return policy?</span>
                      <span className="icon">
                        <i className="lastudioicon-down-arrow" />
                      </span>
                    </button>
                    <div id="faqSix" className="accordion-collapse collapse">
                      <p>
                        We do not accept returns or cancellations after payment.
                        However, amendments can be done in the case of factory
                        error.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <button
                      className="collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqSeven"
                    >
                      <span className="text">Do you ship overseas?</span>
                      <span className="icon">
                        <i className="lastudioicon-down-arrow" />
                      </span>
                    </button>
                    <div id="faqSeven" className="accordion-collapse collapse">
                      <p>
                        Yes, we ship all over the world. However, shipping cost
                        will apply.
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <button
                      className="collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqEight"
                    >
                      <span className="text">
                        Are purchases on preorder basis?
                      </span>
                      <span className="icon">
                        <i className="lastudioicon-down-arrow" />
                      </span>
                    </button>
                    <div id="faqEight" className="accordion-collapse collapse">
                      <p>For now, purchases are strictly on preorder basis.</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* FAQ’s End */}
            </div>
            {/* FAQ’s Wrapper End */}
          </div>
        </div>
        {/* FAQ’s End */}
      </main>

      <Footer />
    </>
  );
};

export default Faq;
