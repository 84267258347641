import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Faq from "./pages/Faq";
import Women from "./pages/shop/Women";
import Men from "./pages/shop/Men";
import Runway from "./pages/shop/Runway";
import Trending from "./pages/shop/Trending";
import Bridal from "./pages/shop/Bridal";
import ColourCollections from "./pages/collections/ColourCollections";
import ResortCapsule from "./pages/collections/ResortCapsule";
import SistersKeeperCollection from "./pages/collections/SistersKeeperCollection";
import FashionVaccineEditorial from "./pages/collections/FashionVaccineEditorial";
import NewIn from "./pages/collections/NewIn";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/shop-bridal" element={<Bridal />} />
        <Route path="/shop-colour-collection" element={<ColourCollections />} />
        <Route
          path="/shop-fashion-vaccine-editorial"
          element={<FashionVaccineEditorial />}
        />
        <Route path="/shop-men" element={<Men />} />
        <Route path="/shop-new-in" element={<NewIn />} />
        <Route path="/shop-resort-capsule" element={<ResortCapsule />} />
        <Route path="/shop-runway" element={<Runway />} />
        <Route
          path="/shop-sisters-keeper-collection"
          element={<SistersKeeperCollection />}
        />
        <Route path="/shop-trending" element={<Trending />} />
        <Route path="/shop-women" element={<Women />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default App;
