const Features = () => {
  return (
    <div className="features-section">
      <div className="container-fluid custom-container">
        <div className="row">
          <div className="col-md-3 col-6">
            <div className="single-features single-features--dark text-center ShortFadeInUp">
              <div className="single-features__icon">
                <i className="dlicon design_scissors-dashed" />
              </div>
              <div className="single-features__content">
                <h4 className="single-features__content--title">
                  Perfectly Tailored
                </h4>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="single-features single-features--dark text-center ShortFadeInUp">
              <div className="single-features__icon">
                <i className="dlicon shopping_delivery-fast" />
              </div>
              <div className="single-features__content">
                <h4 className="single-features__content--title">
                  Express Shipping
                </h4>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-6">
            {/* Single Features Start */}
            <div className="single-features single-features--dark text-center ShortFadeInUp">
              <div className="single-features__icon">
                <i className="dlicon holidays_cockade" />
              </div>
              <div className="single-features__content">
                <h4 className="single-features__content--title">
                  Unique Brand
                </h4>
              </div>
            </div>
            {/* Single Features End */}
          </div>
          <div className="col-md-3 col-6">
            {/* Single Features Start */}
            <div className="single-features single-features--dark text-center ShortFadeInUp">
              <div className="single-features__icon">
                <i className="lastudioicon lastudioicon-support248" />
              </div>
              <div className="single-features__content">
                <h4 className="single-features__content--title">
                  Fast Support
                </h4>
              </div>
            </div>
            {/* Single Features End */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
