import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <>
      <Header activePage="contact" />
      <main style={{ paddingTop: "170px" }}>
        {/* Breadcrumb Start */}
        <div className="breadcrumb-section" style={{ paddingTop: "0" }}>
          <div className="container-fluid custom-container">
            <div className="breadcrumb-wrapper text-center">
              <h2 className="breadcrumb-wrapper__title">Contact Us</h2>
              <ul className="breadcrumb-wrapper__items justify-content-center">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <span>Contact Us</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Breadcrumb End */}
        {/* Contact Us Start */}
        <div className="contact-us-section section-padding-2">
          <div className="container-fluid custom-container">
            {/* Contact Us Wrapper Start */}
            <div className="contact-us-wrapper">
              <div className="row gy-5">
                <div className="col-md-12">
                  {/* Contact Us Start */}
                  <div className="contact-us">
                    <h2 className="contact-us__title">Contact info</h2>
                    {/* Contact Us Info Start */}
                    <div className="contact-us-info">
                      {/* Contact Us Info Start */}
                      <div className="contact-info-item">
                        <h4 className="contact-info-item__title">Call Us:</h4>
                        <p>
                          We’re available from 9 am – 6 pm WAT, Monday to
                          Saturday.
                        </p>
                        <div className="contact-info-item__service">
                          <h4 className="contact-info-item__service--title">
                            Customer Service
                          </h4>
                          <p>
                            <a href="tel:+2349072645016">09072645016</a>
                          </p>
                        </div>
                      </div>
                      {/* Contact Us Info End */}
                      {/* Contact Us Info Start */}
                      <div className="contact-info-item">
                        <h4 className="contact-info-item__title">Email Us</h4>
                        <p>
                          <a href="mailto:chioma.akpuru@gmail.com">
                            chioma.akpuru@gmail.com
                          </a>
                        </p>
                      </div>
                      {/* Contact Us Info End */}
                    </div>
                    {/* Contact Us Info End */}
                  </div>
                  {/* Contact Us End */}
                </div>
              </div>
            </div>
            {/* Contact Us Wrapper End */}
          </div>
        </div>
        {/* Contact Us End */}
      </main>
      <Footer />
    </>
  );
};

export default Contact;
