import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

const NotFound = () => {
  return (
    <>
      <Header />
      <div className="error-section" style={{ paddingTop: "250px" }}>
        <div className="container-fluid faq-container">
          <div className="error-content text-center">
            <img
              className="error-content__icon"
              src="/assets/images/404.svg"
              alt="404"
            />
            <h2 className="error-content__title">404. Page not found.</h2>
            <p>
              Sorry, we couldn’t find the page you were looking for. We suggest
              that you return to homepage.
            </p>
            <Link className="error-content__btn btn" to="/">
              Back to homepage
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default NotFound;
