import Header from "../components/Header";
import Footer from "../components/Footer";
import creativity from "../assets/dora-5.png";
import artistic from "../assets/mimi-3.png";
import innovative from "../assets/kitay.png";
import ca from "../assets/ca.jpeg";

const About = () => {
  return (
    <>
      <Header activePage="about" />
      {/* About Start */}
      <div
        className="about-section section-padding"
        style={{ paddingTop: "170px" }}
      >
        <div className="container-fluid custom-container">
          {/* About Title Start */}
          <div className="about-title text-center ShortFadeInUp">
            <h2 className="about-title__title">We Love Fashion</h2>
            <p>
              Chioma Akpuru owned by Nigerian fashion designer Chioma Akpuru
              Ugwu is a fashion brand, the future light of African fashion which
              specializes in contemporary fashion for both men and women. The
              brand celebrates the inherent, ethnic glamour found in local
              African and contemporary fabrics. Our style is greatly inspired by
              what we see (shapes, nature, art, people, food, culture). We
              preach multifunctionality and versatility. Our goal is to change
              the world with our fashion; piece by piece, correcting societal
              ills through the art of story telling and changing the narrative
              of conforming.
            </p>
            <div className="ca-section">
              <img src={ca} alt="Chioma Akpuru" className="ca" />
              <p>
                Born in 1996, with a flair for fashion, Chioma advanced her
                tailoring skills and gained mentorship about fashion which later
                fueled the genesis of the C.A brand. The Chioma Akpuru team
                works together to ensure that stories are told through every
                piece created. C.A’s vision is to persistently build and embrace
                cultural stories through design that celebrates the bold,
                expressive and confident man and woman that is the C.A Tribe.
              </p>
            </div>
          </div>
          {/* About Title End */}
          {/* About Items Start */}
          <div className="about-items">
            <div className="about-col">
              {/* About Item Start */}
              <div className="about-item ShortFadeInUp">
                <div className="about-item__top">
                  <a href="#/">
                    <div className="about-item__top--image">
                      <img
                        src={creativity}
                        alt="About"
                        style={{ height: "480px", objectFit: "cover" }}
                      />
                    </div>
                    <h3 className="about-item__top--title">Creative</h3>
                  </a>
                </div>
                <div className="about-item__bottom">
                  <div className="about-item__bottom--count">01</div>
                  <p className="about-item__bottom--description">
                    Crafting garments that stand out for their originality and
                    imaginative flair.
                  </p>
                </div>
              </div>
              {/* About Item End */}
            </div>
            <div className="about-col">
              {/* About Item Start */}
              <div className="about-item ShortFadeInUp">
                <div className="about-item__top">
                  <a href="#/">
                    <div className="about-item__top--image">
                      <img
                        src={artistic}
                        alt="About"
                        style={{ height: "480px", objectFit: "cover" }}
                      />
                    </div>
                    <h3 className="about-item__top--title">Artistic</h3>
                  </a>
                </div>
                <div className="about-item__bottom">
                  <div className="about-item__bottom--count">02</div>
                  <p className="about-item__bottom--description">
                    Infusing creativity into every design, treating fashion as a
                    form of artistic expression.
                  </p>
                </div>
              </div>
              {/* About Item End */}
            </div>
            <div className="about-col">
              {/* About Item Start */}
              <div className="about-item ShortFadeInUp">
                <div className="about-item__top">
                  <a href="#/">
                    <div className="about-item__top--image">
                      <img
                        src={innovative}
                        alt="About"
                        style={{ height: "480px", objectFit: "cover" }}
                      />
                    </div>
                    <h3 className="about-item__top--title">Innovative</h3>
                  </a>
                </div>
                <div className="about-item__bottom">
                  <div className="about-item__bottom--count">03</div>
                  <p className="about-item__bottom--description">
                    Pushing boundaries, introducing new ideas and setting trends
                    and redefining fashion norms.
                  </p>
                </div>
              </div>
              {/* About Item End */}
            </div>
          </div>
          {/* About Items End */}
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
};

export default About;
