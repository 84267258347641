import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/chioma-akpuru-logo.png";

const Header = (props: any) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileDropdownOpen, setMobileDropdownOpen] = useState(false);
  const [mobileDropdownTwoOpen, setMobileDropdownTwoOpen] = useState(false);

  const openSideBar = () => {
    setSidebarOpen(true);
  };

  const closeSideBar = () => {
    setSidebarOpen(false);
  };

  const openMobileMenu = () => {
    setMobileMenuOpen(true);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const toggleMobileDropdown = () => {
    setMobileDropdownOpen(!mobileDropdownOpen);
  };

  const toggleMobileDropdownTwo = () => {
    setMobileDropdownTwoOpen(!mobileDropdownTwoOpen);
  };

  return (
    <>
      <header className="header bg-white header-height">
        <div className="header-2__top">
          <div className="container-fluid custom-container">
            <div className="header-2__top--wrapper">
              <div className="header-2__top--left d-none d-md-block">
                <ul className="header-2__top--items">
                  <li>
                    <a href="mailto:chioma.akpuru@gmail.com">
                      <i className="lastudioicon-mail-2" />
                      <span>chioma.akpuru@gmail.com</span>
                    </a>
                  </li>
                  <li>
                    <a href="tel:+2349072645016">
                      <i className="lastudioicon-phone-call" />
                      <span>09072645016</span>
                    </a>
                  </li>
                  <li>
                    <a href="#/">
                      <i className="lastudioicon-pin-3-1" />
                      <span>Lagos, Nigeria</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="header__main header-shadow d-flex align-items-center">
          <div className="container-fluid custom-container">
            <div className="row align-items-center position-relative">
              <div className="col-md-4 col-3 d-xl-none">
                <button
                  onClick={openMobileMenu}
                  className="header__main--toggle header__main--toggle-dark"
                  // data-bs-toggle="offcanvas"
                  // data-bs-target="#mobileMenu"
                >
                  <i className="lastudioicon-menu-8-1" />
                </button>
              </div>
              <div className="col-xl-3 col-md-4 col-6">
                <div className="header__main--logo">
                  <Link
                    className="justify-content-center justify-content-xl-start"
                    to="/"
                  >
                    <img src={logo} alt="Logo" className="logoimg" />
                  </Link>
                </div>
              </div>
              <div className="col-xl-6 d-none d-xl-block">
                <div className="header__main--menu">
                  <nav className="navbar-menu">
                    <ul className="menu-items-list menu-items-list--dark d-flex justify-content-center">
                      <li className="position-static">
                        <Link
                          className={`${
                            props.activePage === "home" ? "active" : ""
                          }`}
                          to="/"
                        >
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`${
                            props.activePage === "about" ? "active" : ""
                          }`}
                          to="/about"
                        >
                          About Us
                        </Link>
                      </li>
                      <li>
                        <a href="#/">
                          Shop <i className="lastudioicon-down-arrow" />
                        </a>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/shop-women">Women</Link>
                          </li>
                          <li>
                            <Link to="/shop-bridal">Bridal</Link>
                          </li>
                          <li>
                            <Link to="/shop-men">Men</Link>
                          </li>
                          <li>
                            <Link to="/shop-runway">Runway</Link>
                          </li>
                          <li>
                            <Link to="/shop-trending">Trending</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="#/">
                          Collections <i className="lastudioicon-down-arrow" />
                        </a>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/shop-colour-collection">
                              Colour Collection
                            </Link>
                          </li>
                          <li>
                            <Link to="/shop-resort-capsule">
                              Resort Capsule
                            </Link>
                          </li>
                          <li>
                            <Link to="/shop-sisters-keeper-collection">
                              Sister’s Keeper Collection
                            </Link>
                          </li>
                          <li>
                            <Link to="/shop-fashion-vaccine-editorial">
                              Fashion Vaccine Editorial
                            </Link>
                          </li>
                          <li>
                            <Link to="/shop-new-in">New In</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link
                          className={`${
                            props.activePage === "contact" ? "active" : ""
                          }`}
                          to="/contact"
                        >
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="col-xl-3 col-md-4 col-3">
                <div className="header__main--meta header__main--dark d-flex justify-content-end align-items-center">
                  <button
                    className="toggle-icon d-none d-xl-block"
                    onClick={openSideBar}
                    // data-bs-toggle="offcanvas"
                    // data-bs-target="#offcanvasSidebar"
                  >
                    <span className="bar-icon">
                      <i className="lastudioicon-menu-8-1" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {sidebarOpen && (
        <div
          className="offcanvas offcanvas-end offcanvas-sidebar show"
          tabIndex={-1}
          id="offcanvasSidebar"
        >
          <button
            type="button"
            className="offcanvas-sidebar__close"
            onClick={closeSideBar}
          >
            <i className="lastudioicon-e-remove" />
          </button>
          <div className="offcanvas-body">
            {/* Off Canvas Sidebar Menu Start */}
            <div className="offcanvas-sidebar__menu">
              <ul className="offcanvas-menu-list">
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/faq">FAQ's</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>
            {/* Off Canvas Sidebar Menu End */}
            {/* Off Canvas Sidebar Banner Start */}
            <div
              className="offcanvas-sidebar__banner"
              style={{
                backgroundImage: "url(assets/images/products/zilo-1.jpeg)",
              }}
            >
              <h3 className="banner-title">NEW NOW</h3>
              <h4 className="banner-sub-title">THE ZILO SHIRT</h4>
            </div>
            {/* Off Canvas Sidebar Banner End */}
            {/* Off Canvas Sidebar Info Start */}
            <div className="offcanvas-sidebar__info">
              <ul className="offcanvas-info-list">
                <li>
                  <a href="tel:+2349072645016">09072645016</a>
                </li>
                <li>
                  <a href="mailto:chioma.akpuru@gmail.com">
                    chioma.akpuru@gmail.com
                  </a>
                </li>
              </ul>
            </div>
            {/* Off Canvas Sidebar Info End */}
            {/* Off Canvas Sidebar Social Start */}
            <div className="offcanvas-sidebar__social">
              <ul className="offcanvas-social">
                <li>
                  <a
                    href="https://www.facebook.com/Cheeoma.akpuru/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="lastudioicon-b-facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/cakpuru?s=21&t=IY6-XPKfGMUqkI6osDaoUA"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="lastudioicon-b-twitter" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://instagram.com/chioma.akpuru?igshid=YmMyMTA2M2Y="
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="lastudioicon-b-instagram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://youtube.com/channel/UCjRBbOPWAAHnndmmlSkPr9A"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="lastudioicon-b-youtube" />
                  </a>
                </li>
              </ul>
            </div>
            {/* Off Canvas Sidebar Social End */}
          </div>
        </div>
      )}

      {mobileMenuOpen && (
        <div
          className="mobile-menu offcanvas offcanvas-start show"
          tabIndex={-1}
          id="mobileMenu"
        >
          <div className="offcanvas-header">
            <button
              onClick={closeMobileMenu}
              type="button"
              className="mobile-menu__close"
              // data-bs-dismiss="offcanvas"
              // aria-label="Close"
            >
              <i className="lastudioicon-e-remove" />
            </button>
          </div>
          <div className="offcanvas-body">
            <nav className="navbar-mobile-menu">
              <ul className="mobile-menu-items">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li className={`${mobileDropdownOpen ? "active" : ""}`}>
                  <a href="#/" onClick={toggleMobileDropdown}>
                    Shop
                    <span className="menu-expand">
                      <i className="lastudioicon-down-arrow" />
                    </span>
                  </a>
                  <div
                    className="mega-menu"
                    style={
                      mobileDropdownOpen
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <div className="mega-menu-col">
                      <ul>
                        <li>
                          <Link to="/shop-women">Women</Link>
                        </li>
                        <li>
                          <Link to="/shop-bridal">Bridal</Link>
                        </li>
                        <li>
                          <Link to="/shop-men">Men</Link>
                        </li>
                        <li>
                          <Link to="/shop-runway">Runway</Link>
                        </li>
                        <li>
                          <Link to="/shop-trending">Trending</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className={`${mobileDropdownTwoOpen ? "active" : ""}`}>
                  <a href="#/" onClick={toggleMobileDropdownTwo}>
                    Collections
                    <span className="menu-expand">
                      <i className="lastudioicon-down-arrow" />
                    </span>
                  </a>
                  <div
                    className="mega-menu"
                    style={
                      mobileDropdownTwoOpen
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <div className="mega-menu-col">
                      <ul>
                        <li>
                          <Link to="/shop-colour-collection">
                            Colour Collection
                          </Link>
                        </li>
                        <li>
                          <Link to="/shop-resort-capsule">Resort Capsule</Link>
                        </li>
                        <li>
                          <Link to="/shop-sisters-keeper-collection">
                            Sister’s Keeper Collection
                          </Link>
                        </li>
                        <li>
                          <Link to="/shop-fashion-vaccine-editorial">
                            Fashion Vaccine Editorial
                          </Link>
                        </li>
                        <li>
                          <Link to="/shop-new-in">New In</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </nav>
          </div>
          {/* offcanvas-body end */}
        </div>
      )}

      {sidebarOpen && (
        <div className="offcanvas-backdrop fade show" onClick={closeSideBar} />
      )}
    </>
  );
};

export default Header;
