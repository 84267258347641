import { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import data from "../../data.json";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";

const NewIn = () => {
  let products = data.filter((product: any) =>
    product.collection?.includes("new-in")
  );

  const displayedProducts = 16;
  const [productsToDisplay, setProductsToDisplay] = useState(displayedProducts);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [productToView, setProductToView] = useState<any>({});

  const openViewModal = () => {
    setViewModalOpen(true);
  };

  const closeViewModal = () => {
    setViewModalOpen(false);
  };

  const loadMoreProducts = () => {
    setProductsToDisplay(productsToDisplay + displayedProducts);
  };

  return (
    <>
      <Header />
      <main style={{ paddingTop: "170px" }}>
        {/* Breadcrumb Start */}
        <div className="breadcrumb-section" style={{ paddingTop: "0" }}>
          <div className="container-fluid custom-container">
            <div className="breadcrumb-wrapper text-center">
              <h2 className="breadcrumb-wrapper__title">Collections</h2>
              <ul className="breadcrumb-wrapper__items justify-content-center">
                <li>
                  <span>Collections</span>
                </li>
                <li>
                  <span>New In</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Breadcrumb End */}
        {/* Shop Start */}
        <div className="shop-section section-padding-2">
          <div className="container-fluid custom-container">
            {/* Shop Wrapper Start */}
            <div className="shop-wrapper">
              <div className="row">
                {products?.length > 0 && (
                  <>
                    {products
                      ?.sort((a: any, b: any) => b.id - a.id)
                      ?.slice(0, productsToDisplay)
                      ?.map((product: any) => (
                        <div
                          className="col-lg-3 col-md-4 col-sm-6"
                          key={product.id}
                        >
                          {/* Single product Start */}
                          <div className="single-product ShortFadeInUp">
                            <div className="single-product__thumbnail">
                              <div className="single-product__thumbnail--holder">
                                <a href="#/">
                                  <img src={product.images[0]} alt="Product" />
                                </a>
                              </div>
                              <div className="single-product__thumbnail--meta-2">
                                <button
                                  onClick={() => {
                                    setProductToView(product);
                                    openViewModal();
                                  }}
                                >
                                  <i className="lastudioicon-search-zoom-in" />
                                </button>
                              </div>
                            </div>
                            <div className="single-product__info text-center">
                              <div className="single-product__info--tags">
                                <a href="#/">New In</a>
                              </div>
                              <h3 className="single-product__info--title">
                                {product.name}
                              </h3>
                              <div className="single-product__info--price">
                                <ins>
                                  &#8358;{product?.price?.toLocaleString()}
                                </ins>
                              </div>
                            </div>
                          </div>
                          {/* Single product End */}
                        </div>
                      ))}
                  </>
                )}
              </div>
            </div>
            {/* Shop Wrapper End */}
            {productsToDisplay < products?.length && (
              <div className="text-center">
                <a
                  href="#/"
                  className="btn product-view-btn"
                  onClick={loadMoreProducts}
                >
                  Load More Products
                </a>
              </div>
            )}
          </div>
        </div>
        {/* Shop End */}
      </main>

      {viewModalOpen && (
        <>
          <div className="modal quickview-modal fade show" id="quickView">
            <div className="modal-dialog modal-dialog-centered">
              {/* Modal Content Start */}
              <div className="modal-content">
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeViewModal}
                >
                  <i className="lastudioicon-e-remove" />
                </button>
                <div className="modal-body">
                  <div className="row g-0">
                    <div className="col-md-6">
                      {/* Product Single image Start */}
                      <div className="product-single-image">
                        <div className="quick-view-product-slide navigation-arrows-style-2">
                          <div className="swiper">
                            <Swiper
                              spaceBetween={0}
                              navigation={{
                                nextEl:
                                  ".quick-view-product-slide .swiper-button-next",
                                prevEl:
                                  ".quick-view-product-slide .swiper-button-prev",
                              }}
                              modules={[Navigation]}
                            >
                              <div className="swiper-wrapper">
                                <SwiperSlide>
                                  <div className="product-single-slide-item swiper-slide">
                                    <img
                                      src={productToView?.images[0]}
                                      alt="product"
                                      style={{ objectFit: "contain" }}
                                    />
                                  </div>
                                </SwiperSlide>
                                {productToView?.images.length > 1 && (
                                  <SwiperSlide>
                                    <div className="product-single-slide-item swiper-slide">
                                      <img
                                        src={productToView?.images[1]}
                                        alt="product"
                                        style={{ objectFit: "contain" }}
                                      />
                                    </div>
                                  </SwiperSlide>
                                )}
                                {productToView?.images.length > 2 && (
                                  <SwiperSlide>
                                    <div className="product-single-slide-item swiper-slide">
                                      <img
                                        src={productToView?.images[2]}
                                        alt="product"
                                        style={{ objectFit: "contain" }}
                                      />
                                    </div>
                                  </SwiperSlide>
                                )}
                              </div>
                            </Swiper>
                            <div className="swiper-button-next">
                              <i className="lastudioicon-arrow-right" />
                            </div>
                            <div className="swiper-button-prev">
                              <i className="lastudioicon-arrow-left" />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Product Single image End */}
                    </div>
                    <div className="col-md-6">
                      {/* Product Single Content Start */}
                      <div className="product-single-content quick-view-product-content">
                        <h2 className="product-single-content__title">
                          {productToView.name}
                        </h2>
                        <div className="product-single-content__price-stock">
                          <div className="product-single-content__price">
                            <ins>
                              &#8358;{productToView?.price?.toLocaleString()}
                            </ins>
                          </div>
                        </div>
                        <p>
                          Price stated applies to size 6-14
                          <br /> Sizes above 14 attracts an additional
                          &#8358;5,000
                        </p>
                        {productToView?.description && (
                          <div className="product-single-content__short-description">
                            <p>{productToView.description}</p>
                          </div>
                        )}

                        <div className="product-single-content__meta">
                          <div className="product-single-content__meta--item">
                            <div className="label">Categories:</div>
                            <div className="content">
                              {productToView?.categories?.map((cat: any) => (
                                <a key={cat} href="#/">
                                  {cat},
                                </a>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Product Single Content End */}
                    </div>
                  </div>
                </div>
              </div>
              {/* Modal Content End */}
            </div>
          </div>
          <div
            className="offcanvas-backdrop fade show"
            onClick={closeViewModal}
          />
        </>
      )}

      <Footer />
    </>
  );
};

export default NewIn;
