import { useState } from "react";
import Header from "../components/Header";
import bannerImage from "../assets/blazer.jpeg";
import Features from "../components/Features";
import Footer from "../components/Footer";
import didi from "../assets/didi-1.png";
import bridal from "../assets/wedding-1.png";
import men from "../assets/ugo-2.png";
import runway from "../assets/cullote.png";
import trending from "../assets/blazer-2.png";
import { Link } from "react-router-dom";
import data from "../data.json";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";

const Home = () => {
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [productToView, setProductToView] = useState<any>({});

  const openViewModal = () => {
    setViewModalOpen(true);
  };

  const closeViewModal = () => {
    setViewModalOpen(false);
  };

  let products = data?.slice(-8).sort((a: any, b: any) => b.id - a.id);

  return (
    <>
      <Header activePage="home" />
      <div className="slider-section slider-active">
        <div>
          <div>
            <div className="home-3-slider-style bg-blur">
              <div className="container-fluid custom-container position-relative">
                <div className="home-3-slider-style__sub-title">
                  HOT TREND NOW
                </div>
                <div className="home-3-slider-style__image parallax">
                  <img src={bannerImage} alt="banner" />
                </div>
                <div className="home-3-slider-content-style-1">
                  <h1 className="home-3-slider-content-style-1__title">
                    FULLY STONED
                    <br /> BLAZER JACKET
                  </h1>
                  <p className="home-3-slider-content-style-1__description">
                    Elevate your style with this sophisticated blazer,
                    meticulously crafted with a unique stoned texture for a
                    touch of modern luxury. Perfect for making a bold fashion
                    statement.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Features />
      <div className="categories-section">
        <div className="container-fluid custom-container">
          <div className="categories-wrapper">
            <div className="row grid-3">
              <div className="col-lg-6 col-md-4 grid-item">
                {/* Categories Start */}
                <div className="categories-item ShortFadeInUp">
                  <div className="categories-item__image">
                    <Link to="/shop-women">
                      <img src={didi} alt="women" />
                    </Link>
                  </div>
                  <div className="categories-item__content">
                    <span>For Women</span>
                  </div>
                </div>
                {/* Categories End */}
              </div>
              <div className="col-lg-3 col-md-4 grid-item">
                {/* Categories Start */}
                <div className="categories-item ShortFadeInUp">
                  <div className="categories-item__image">
                    <Link to="/shop-bridal">
                      <img src={bridal} alt="bridal" />
                    </Link>
                  </div>
                  <div className="categories-item__content">
                    <span>Bridal</span>
                  </div>
                </div>
                {/* Categories End */}
              </div>
              <div className="col-lg-3 col-md-4 grid-item">
                {/* Categories Start */}
                <div className="categories-item ShortFadeInUp">
                  <div className="categories-item__image">
                    <Link to="/shop-men">
                      <img src={men} alt="men" />
                    </Link>
                  </div>
                  <div className="categories-item__content">
                    <span>For Men</span>
                  </div>
                </div>
                {/* Categories End */}
              </div>
              <div className="col-lg-6 col-md-4 grid-item">
                {/* Categories Start */}
                <div className="categories-item ShortFadeInUp">
                  <div className="categories-item__image">
                    <Link to="/shop-runway">
                      <img src={runway} alt="runway" />
                    </Link>
                  </div>
                  <div className="categories-item__content">
                    <span>Runway</span>
                  </div>
                </div>
                {/* Categories End */}
              </div>
              <div className="col-lg-6 col-md-4 grid-item">
                {/* Categories Start */}
                <div className="categories-item ShortFadeInUp">
                  <div className="categories-item__image">
                    <Link to="/shop-trending">
                      <img src={trending} alt="trending" />
                    </Link>
                  </div>
                  <div className="categories-item__content">
                    <span>Trending</span>
                  </div>
                </div>
                {/* Categories End */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="new-arrival-section section-padding">
        <div className="container-fluid custom-container">
          <div className="section-title-2 text-center ShortFadeInUp">
            <h3 className="section-title-2__title">NEW ARRIVALS</h3>
          </div>

          <div className="tab-content">
            <div className="tab-pane fade show active" id="allProducts">
              {/* New Arrival Wrapper Start */}
              <div className="new-arrival-wrapper-2">
                <div className="row g-3">
                  {products?.map((product) => (
                    <div className="col-lg-3 col-md-4" key={product.id}>
                      {/* Single product Start */}
                      <div className="single-product ShortFadeInUp">
                        <div className="single-product__thumbnail">
                          <div className="single-product__thumbnail--holder">
                            <a href="#/">
                              <img src={product?.images[0]} alt="Product" />
                            </a>
                          </div>
                          <div className="single-product__thumbnail--meta-2">
                            <button
                              onClick={() => {
                                setProductToView(product);
                                openViewModal();
                              }}
                            >
                              <i className="lastudioicon-search-zoom-in" />
                            </button>
                          </div>
                        </div>
                        <div className="single-product__info text-center">
                          <h3 className="single-product__info--title">
                            <a href="#/">{product.name}</a>
                          </h3>
                          <div className="single-product__info--price">
                            <ins>&#8358;{product?.price?.toLocaleString()}</ins>
                          </div>
                        </div>
                      </div>
                      {/* Single product End */}
                    </div>
                  ))}
                </div>
              </div>
              {/* New Arrival Wrapper End */}
            </div>
          </div>
          {/* Product Tab Content End */}
        </div>
      </div>

      {viewModalOpen && (
        <>
          <div className="modal quickview-modal fade show" id="quickView">
            <div className="modal-dialog modal-dialog-centered">
              {/* Modal Content Start */}
              <div className="modal-content">
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeViewModal}
                >
                  <i className="lastudioicon-e-remove" />
                </button>
                <div className="modal-body">
                  <div className="row g-0">
                    <div className="col-md-6">
                      {/* Product Single image Start */}
                      <div className="product-single-image">
                        <div className="quick-view-product-slide navigation-arrows-style-2">
                          <div className="swiper">
                            <Swiper
                              spaceBetween={0}
                              navigation={{
                                nextEl:
                                  ".quick-view-product-slide .swiper-button-next",
                                prevEl:
                                  ".quick-view-product-slide .swiper-button-prev",
                              }}
                              modules={[Navigation]}
                            >
                              <div className="swiper-wrapper">
                                <SwiperSlide>
                                  <div className="product-single-slide-item swiper-slide">
                                    <img
                                      src={productToView?.images[0]}
                                      alt="product"
                                      style={{ objectFit: "contain" }}
                                    />
                                  </div>
                                </SwiperSlide>
                                {productToView?.images.length > 1 && (
                                  <SwiperSlide>
                                    <div className="product-single-slide-item swiper-slide">
                                      <img
                                        src={productToView?.images[1]}
                                        alt="product"
                                        style={{ objectFit: "contain" }}
                                      />
                                    </div>
                                  </SwiperSlide>
                                )}
                                {productToView?.images.length > 2 && (
                                  <SwiperSlide>
                                    <div className="product-single-slide-item swiper-slide">
                                      <img
                                        src={productToView?.images[2]}
                                        alt="product"
                                        style={{ objectFit: "contain" }}
                                      />
                                    </div>
                                  </SwiperSlide>
                                )}
                              </div>
                            </Swiper>
                            <div className="swiper-button-next">
                              <i className="lastudioicon-arrow-right" />
                            </div>
                            <div className="swiper-button-prev">
                              <i className="lastudioicon-arrow-left" />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Product Single image End */}
                    </div>
                    <div className="col-md-6">
                      {/* Product Single Content Start */}
                      <div className="product-single-content quick-view-product-content">
                        <h2 className="product-single-content__title">
                          {productToView.name}
                        </h2>
                        <div className="product-single-content__price-stock">
                          <div className="product-single-content__price">
                            <ins>
                              &#8358;{productToView?.price?.toLocaleString()}
                            </ins>
                          </div>
                        </div>
                        {productToView?.description && (
                          <div className="product-single-content__short-description">
                            <p>{productToView.description}</p>
                          </div>
                        )}

                        <div className="product-single-content__meta">
                          <div className="product-single-content__meta--item">
                            <div className="label">Categories:</div>
                            <div className="content">
                              {productToView?.categories?.map((cat: any) => (
                                <a key={cat} href="#/">
                                  {cat},
                                </a>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Product Single Content End */}
                    </div>
                  </div>
                </div>
              </div>
              {/* Modal Content End */}
            </div>
          </div>
          <div
            className="offcanvas-backdrop fade show"
            onClick={closeViewModal}
          />
        </>
      )}

      <Footer />
    </>
  );
};

export default Home;
